<template>
  <div>
    <vx-card>
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="PO Header"
                icon-pack="feather"
                :icon="errors.tabHeader.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabHeader.isError ? 'danger' : null">
          <TabHeader :initData="initDataTabHeader"/>
        </vs-tab>

        <vs-tab label="Detail Barang"
                icon-pack="feather"
                :icon="errors.tabBarang.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabBarang.isError ? 'danger' : null">
          <TabBarang :initData="initDataTabBarang"/>
        </vs-tab>

        <vs-tab label="Termin Pembayaran"
                icon-pack="feather"
                :icon="errors.tabPembayaran.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabPembayaran.isError ? 'danger' : null">
          <TabPembayaran :initData="initDataTabPembayaran"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 2">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import PoRepository from '@/repositories/proyek/po-repository'
import { mapState } from 'vuex'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import modulePoAdd from '@/store/modules/proyek/po/po-add.store'

export default {
  name: 'PoEdit',
  components: {
    TabHeader: () => import('@/views/pages/proyek/po/parts/TabHeader'),
    TabBarang: () => import('@/views/pages/proyek/po/parts/TabBarang'),
    TabPembayaran: () => import('@/views/pages/proyek/po/parts/TabPembayaran')
  },
  mounted () {
    this.$store.commit('proyek/poAdd/SET_ANY_UNSAVED_CHANGES', true) // enable by default
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      initDataTabHeader: null,
      initDataTabBarang: null,
      initDataTabPembayaran: null
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.proyek.poAdd.activeTab
      },
      set (value) {
        this.$store.commit('proyek/poAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.proyek.poAdd.errors,
      isAnyUnsavedChanges: state => state.proyek.poAdd.isAnyUnsavedChanges,
      dataTabHeader: state => state.proyek.poAdd.tabHeader,
      dataTabBarang: state => state.proyek.poAdd.tabBarang,
      dataTabPembayaran: state => state.proyek.poAdd.tabPembayaran
    })
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idPo = this.$route.params.idPo
      PoRepository.show(idPo)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildInitData (initData) {
      // tab header
      const initDataTabHeader = _.pick(initData.header, ['id_proyek', 'id_rekanan', 'nama_rekanan', 'jenis_po', 'no_po', 'tgl_po', 'keterangan'])

      // tab barang
      const initDataTabBarang = _.map(initData.barang, item => {
        return {
          ..._.pick(item, ['id', 'id_item_pengadaan', 'id_pengadaan', 'id_pengadaan_d', 'no_pengadaan', 'nama_item_pengadaan', 'satuan_item_pengadaan', 'harga_satuan', 'keterangan']),
          uuid: uuid(),
          qty_actual: item.qty
        }
      })

      // tab pembayaran
      const initDataTabPembayaran = _.pick(initData.header, ['nilai_total', 'ppn_jenis', 'ppn_key', 'ppn_persen', 'ppn_jml', 'pph_nama', 'pph_key', 'pph_persen', 'pph_jml', 'is_input_termin_by_percentage'])
      initDataTabPembayaran.id_cara_bayar_coa_credit = initData.header.id_cara_bayar
      initDataTabPembayaran.deletedIds = []
      initDataTabPembayaran.items = _.map(initData.pembayaran, item => {
        return {
          uuid: uuid(),
          ..._.pick(item, ['id', 'no_termin', 'tgl_due', 'persen', 'nilai_total', 'ppn_jml', 'pph_jml', 'jml_total', 'jml_bayar', 'keterangan'])
        }
      })

      // set data with delay for another tab except tab header to avoid bugs
      this.initDataTabHeader = initDataTabHeader
      setTimeout(() => {
        this.initDataTabBarang = initDataTabBarang
        this.initDataTabPembayaran = initDataTabPembayaran
      }, 500)
    },

    save () {
      this.$store.commit('proyek/poAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = {
        id_po: this.$route.params.idPo,
        tabHeader: this.dataTabHeader,
        tabBarang: this.dataTabBarang,
        tabPembayaran: this.dataTabPembayaran
      }
      PoRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            this.$store.commit('proyek/poAdd/SET_ERRORS', errors)
            this.notifyError('Data tidak valid, mohon periksa kembali.')
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.$store.commit('proyek/poAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'proyek.po' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    }
  },
  beforeCreate () {
    this.$store.registerModule(['proyek', 'poAdd'], modulePoAdd)
  },
  beforeDestroy () {
    this.$store.commit('proyek/poAdd/RESET_STATE')
    this.$store.unregisterModule(['proyek', 'poAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
